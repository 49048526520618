<template>
  <div class="addressInput">
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Name</label>
          <input type="text" class="input">
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Company</label>
          <input type="text" class="input">
        </div>
      </div>
    </div>


    <div class="field">
      <label class="label">Address</label>
      <input type="text" class="input">
    </div>

    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">City</label>
          <input type="text" class="input">
        </div>      
      </div>
      <div class="column">
        <div class="field">
          <label class="label">State</label>
          <input type="text" class="input">
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Zip</label>
          <input type="text" class="input">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Address Input',
  data() {
    return {
      name: "",
      address: "",
      company: "",
      city: "",
      state: "",
      zip: ""
    }
  },
  watch: {

  },
  methods: {
    onAddressChange() {
      this.$emit('onChange', {
        name: this.name,
        company: this.company,
        address: this.address,
        city: this.city,
        state: this.state,
        zip: this.zip
      })
    }
  }
}
</script>

