<template>
  <div class="newQuote" style="height: 100%;">

    <div v-if="step == 0" style="height: 100%;" class="is-flex is-align-items-center is-justify-content-center">
      <div style="max-width: 50%;">
        <h2 class="is-size-3">Billing Address</h2>
        <Address></Address>
        <div class="is-flex is-justify-content-end" style="margin-top: 25px;">
          <button class="button is-primary" @click="step = 1">Next</button>
        </div>
      </div>
    </div>
    <div v-if="step == 1" style="height: 100%;" class="is-flex is-align-items-center is-justify-content-center">
      <div style="max-width: 50%;">
        <h2 class="is-size-3">Shipping Address</h2>
        <Address></Address>
        <div class="buttons is-flex is-justify-content-end" style="margin-top: 25px;">
          <button class="button" @click="step = 0">Back</button>

          <button class="button is-primary" @click="step = 2">Next</button>
        </div>
      </div>
    </div>

    <div v-if="step == 2" style="height: 100%;" class="is-flex is-align-items-center is-justify-content-center">
      <div style="max-width: 50%;">
        <h2 class="is-size-3">Name this Quote</h2>
        <input type="text" class="input">
        <div class="buttons is-flex is-justify-content-end" style="margin-top: 25px;">
          <button class="button" @click="step = 1">Back</button>

          <button class="button is-primary">Next</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Address from "@/components/global/inputs/Address"

export default {
  name: 'New Quote',
  components: {
    Address
  },
  data() {
    return {
      step: 0
    }
  }
}
</script>
<style scoped>

  .toggleAddress {
    overflow:hidden;
  }

  .toggle .toggleAddress {
    max-height: 0px;
  }
</style>  
